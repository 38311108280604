<template>
  <div>
      <b-card class="rounded-lg p-1">
        <b-col>
          <b-row class="d-flex mb-1">
            <h3 class="font-weight-bolder">School overview</h3>
            <p>
              <b-link
                class="alert-link cutom-alert-link ml-1 font-weight-normal"
                :to="{ name: 'students' }"
              > 
                View students
                <feather-icon icon="ArrowRightIcon" size="18" />
              </b-link>
            </p>
          </b-row>
          <b-row>
            <b-col class="col-md-3 p-50">
              <div class="d-flex border p-2 rounded-lg shadow-sm">
                <div>
                  <img src="@/assets/images/dashboard/1.png" alt />
                </div>
                <div class="px-1">
                  <p class="m-0">Overall engagement</p>
                  <div class="d-flex align-item-center justify-content-start">
                    <h3 class="font-weight-bolder m-0">18h</h3>&nbsp; / &nbsp;
                    <p class="m-0">weekly</p>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="col-md-3 p-50">
              <div class="d-flex border p-2 rounded-lg shadow-sm">
                <div>
                  <img src="@/assets/images/dashboard/2.png" alt />
                </div>
                <div class="px-1">
                  <p class="m-0">Points scored</p>
                  <div class="d-flex align-item-center justify-content-start">
                    <h3 class="font-weight-bolder m-0">3 430</h3>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="col-md-3 p-50">
              <div class="d-flex border p-2 rounded-lg shadow-sm">
                <div>
                  <img src="@/assets/images/dashboard/3.png" alt />
                </div>
                <div class="px-1">
                  <p class="m-0">Overall progress</p>
                  <div class="d-flex align-item-center justify-content-start">
                    <h3 class="font-weight-bolder m-0">80%</h3>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="col-md-3 p-50">
              <div class="d-flex border p-2 rounded-lg shadow-sm">
                <div>
                  <img src="@/assets/images/dashboard/4.png" alt />
                </div>
                <div class="px-1">
                  <p class="m-0">Missed lessons</p>
                  <div class="d-flex align-item-center justify-content-start">
                    <h3 class="font-weight-bolder m-0">10</h3>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
  </div>
</template>
  
  <script>
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BLink,
  VBTooltip,
  BSpinner,
  BPagination,
  BFormInput,
  BootstrapVueIcons,
  IconsPlugin,
  BIconArrowUp,
  BIcon
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import CardAdvanceEmployeeTask from "@/views/card/card-advance/CardAdvanceEmployeeTask.vue";
import "hooper/dist/hooper.css";
export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BLink,
    ToastificationContent,
    BSpinner,
    BPagination,
    vSelect,
    BFormInput,
    BootstrapVueIcons,
    CardAdvanceEmployeeTask,
    IconsPlugin,
    BIconArrowUp,
    BIcon
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  watch: {},
  computed: {
    ...mapGetters({
    })
  },
  data() {
    return {
      
    };
  },
  methods: {

  },
  mounted() {
    // this.$store.dispatch("courses/list");
    // this.$store.dispatch("modules/list");
  }
};
</script>
  
<style lang="css" scoped>

</style>
  