<template>
  <section id="dashboard-ecommerce">
    
    <div>
      <school-overview/>  
    </div>
    
    <b-row>
      <b-col class="col-md-5 col-sm-12">
        <main-admin-bar-chart/>
      </b-col>
      <b-col class="col-md-7 col-sm-12">
        <main-admin-line-cross/>
      </b-col>
    </b-row>
    <div class="col-12">
      <top-schools/>
    </div>
  
  </section>
</template>

<script>
import schoolOverview from '@/views/dashboard/HODDashboard/schoolOverview.vue'
import topSchools from '@/views/dashboard/HODDashboard/topSchools.vue';
import mainAdminBarChart from './adminDashboard/mainAdminBarChart.vue';
import mainAdminLineCross from './adminDashboard/mainAdminLineCross.vue'
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BSpinner,
  BBadge,
  BCardImg,
  BAlert,
} from "bootstrap-vue";
export default {
  
  components: {
    mainAdminLineCross,
    mainAdminBarChart,
    schoolOverview,
    topSchools,
    BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BSpinner,
  BBadge,
  BCardImg,
  BAlert,
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
